.omega-select {
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    margin-bottom: 3px;
    border: 1px solid rgb(199, 199, 199);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: var(--omega-dropdown-arrow) right 4px center no-repeat #fff;
    background-size: 12px 12px;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    font-size: 14px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.omega-select-wrapper[role='listbox'] {
    position: relative;
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    margin: 0 auto;
    border: 1px solid rgb(199, 199, 199);
    background-color: #fff;
    border-radius: 3px;
    cursor: pointer;
}

.omega-select-wrapper::after {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: #4b4742 transparent;
    content: '';
}

.omega-select-wrapper[aria-disabled='true'] {
    opacity: 0.65;
}

.omega-select-wrapper .omega-select-drop-list {
    position: absolute;
    z-index: 3;
    top: 100%;
    right: 0;
    left: 0;
    display: none;
    max-height: 195px;
    padding: 0;
    margin-top: 0;
    background: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    list-style: none;
    overflow-y: scroll;
    pointer-events: none;
}

.omega-select-wrapper .omega-select-drop-list li {
    display: block;
    height: 28px;
    padding: 5px;
    border-radius: 0 0 3px 3px;
    color: #181818;
    text-decoration: none;
}

.omega-select-wrapper span {
    display: block;
    width: 100%;
    text-align: left;
}

.omega-select-wrapper .omega-select-drop-list li[aria-selected='true'] {
    background-color: #ece8e5;
}

.omega-select-wrapper .omega-select-drop-list li:hover {
    background-color: #ece8e5;
}

.omega-select-wrapper.active::after {
    border-width: 0 6px 6px;
}

.omega-select-wrapper.active .omega-select-drop-list {
    display: block;
    margin-top: 1px;
    border-right: 1px solid rgb(214, 209, 209);
    border-bottom: 1px solid rgb(214, 209, 209);
    border-left: 1px solid rgb(214, 209, 209);
    opacity: 1;
    pointer-events: auto;
}
