.omega-autocomplete[aria-expanded='true'] {
    position: relative;
    height: 32px;
    background-color: white;
    border-radius: 3px 3px 0 0;
}

.omega-autocomplete-button {
    position: relative;
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    margin: 0 auto;
    border: 1px solid rgb(199, 199, 199);
    background-color: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    text-align: left;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.omega-autocomplete-button::after {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: #4b4742 transparent;
    content: '';
}

.omega-autocomplete li {
    display: block;
    height: 28px;
    padding: 5px;
    border-radius: 0 0 3px 3px;
    color: #181818;
    text-decoration: none;
}

.omega-autocomplete li:hover {
    background-color: #ece8e5;
}

.omega-autocomplete[aria-expanded='true'] .omega-autocomplete-button {
    display: none;
    border: none;
    box-shadow: none;
}

.omega-autocomplete[aria-expanded='false'] > :not(.omega-autocomplete-button) {
    display: none;
}

.omega-autocomplete-search-wrapper {
    position: relative;
    top: 0;
    width: 100%;
}

.omega-autocomplete-select-all-label.omega-checkbox-label {
    border-bottom: 1px solid gainsboro;
}

.omega-autocomplete-no-results {
    text-align: center;
}

.omega-autocomplete-search {
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.omega-autocomplete-search-icon {
    position: absolute;
    top: 8px;
    right: 12px;
    color: #888;
    font-size: 14px;
}

.omega-autocomplete-list[role='listbox'] {
    position: absolute;
    z-index: 3;
    top: 33px;
    left: 0;
    width: 100%;
    min-width: 200px;
    max-height: 192px;
    padding: 0;
    margin: 0;
    border: 0;
    border-right: 1px solid rgb(214, 209, 209);
    border-bottom: 1px solid rgb(214, 209, 209);
    border-left: 1px solid rgb(214, 209, 209);
    background-color: white;
    border-radius: 0 0 3px 3px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    list-style: none;
    opacity: 0.99;
    overflow-y: scroll;
}

.omega-autocomplete-list-option:focus {
    border: 1px solid #66afe9;
    box-shadow: 0 2px 4px 0 rgba(102, 175, 233, 0.6);
}

.omega-form-input-box .omega-autocomplete [aria-selected='true'] {
    background: rgb(228, 222, 222);
}

.omega-autocomplete .omega-checkbox-label {
    width: 100%;
}

.omega-autocomplete-list label {
    margin: 0;
    padding: 5px 0 5px 0;
}

.omega-autocomplete-required {
    border: 1px solid red;
    background-color: rgba(204, 16, 16, 0.03);
}
