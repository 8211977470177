@import url('./forms/forms.css');
@import url('./table/table.css');
@import url('./variables.css');
@import url('./dialog.css');
@import url('./account-lookup.css');
@import url('./account-suggestions.css');
@import url('./ach-company-lookup.css');
@import url('./alert/alert.css');
@import url('./forms/download-bar.css');
@import url('./forms/drag-and-drop.css');
@import url('./forms/icon-column.css');
@import url('./layouts/file-format-designer.css');

@font-face {
    font-family: FontAwesome;
    src: url(fonts/fontawesome-webfont.eot) format('embedded-opentype'),
        url(fonts/fontawesome-webfont.woff2) format('woff2'),
        url(fonts/fontawesome-webfont.woff) format('woff'),
        url(fonts/fontawesome-webfont.ttf) format('truetype'),
        url(fonts/fontawesome-webfont.svg) format('svg');
    font-weight: 400;
    font-style: normal;
}

.visually-hidden {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.experimental {
    display: inline-block;
    outline: 2px dashed maroon !important;
}

.experimental .experimental {
    outline: none !important;
}

@media print {
    .omega-filter-print {
        display: none;
        height: 0;
    }
}
