.omega-checkbox {
    position: absolute;
    opacity: 0;
}

.omega-checkbox-label {
    position: relative;
    padding: 3px;
    cursor: pointer;
    font-weight: 400;
    vertical-align: bottom;
}

.omega-checkbox-label:hover {
    background: rgb(228, 222, 222);
}

.omega-checkbox-label::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 5px;
    border: 1px solid rgb(216, 210, 210);
    background: white;
    border-radius: 3px;
    content: '';
    vertical-align: text-top;
}

.omega-single-select-override .omega-checkbox-label::before,
.omega-single-select-override .omega-checkbox-label::after {
    display: none;
}

.omega-checkbox:focus + .omega-checkbox-label::before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.omega-single-select-override .omega-checkbox:focus + .omega-checkbox-label::before {
    box-shadow: none;
}

.omega-checkbox:checked + .omega-checkbox-label::before {
    background: #4389cb;
}

.omega-single-select-override .omega-checkbox:checked + .omega-checkbox-label {
    background: #ece8e5;
}

.omega-checkbox:disabled + .omega-checkbox-label {
    color: #b8b8b8;
    cursor: auto;
}

.omega-checkbox:disabled + .omega-checkbox-label::before {
    background: #ddd;
    box-shadow: none;
}

.omega-checkbox:checked + .omega-checkbox-label::after {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 2px;
    height: 2px;
    background: white;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
    content: '';
    transform: rotate(45deg);
}
